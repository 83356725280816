import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
`;

const EsPageContainer = ({ title, children }) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
      {children}
    </Wrapper>
  );
};

export default EsPageContainer;
