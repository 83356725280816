import React, { useState } from "react";
import OutpayComponent from "./Outpay";
import useEsQuery from "../hooks/useEsQuery";
import env from "../environment";
import Loading from "../components/Loading";

const OutpayContainerComponent = () => {
  const [version, updateVersion] = useState(0);
  const [search, setSearch] = useState("");
  const { data, loading } = useEsQuery(`${env.esavingBE}outpays/`, {
    version,
    search,
  });

  const increaseVersion = () => {
    updateVersion(version + 1);
  };

  if (loading) return <Loading />;

  return (
    <OutpayComponent
      data={data}
      reload={increaseVersion}
      setSearch={setSearch}
    />
  );
};

export default OutpayContainerComponent;
