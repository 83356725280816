import Select from "antd/lib/select";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: left;
`;

const CustomSelect = styled(Select)`
  display: block;
  width: 100%;
`;

const FormSelect = ({
  className,
  isNumber = false,
  name,
  onChange,
  children,
  label,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <label>{label}</label>:
      <CustomSelect
        {...rest}
        name={name}
        onChange={(value) => {
          onChange(name, value);
        }}
      >
        {children}
      </CustomSelect>
    </Wrapper>
  );
};

export default FormSelect;
