import store from "store";
import axios from "axios";
import env from "../environment";
import history from "../myHistory";

export const login = async ({ username, password }) => {
  try {
    delete axios.defaults.headers.common["Authorization"];
    const res = await axios.post(`${env.esavingBE}auth/`, {
      username,
      password,
    });
    const token = res.data.token;
    store.set(
      "user",
      { token: res.data.token },
      new Date().getTime() + 24 * 60 * 60
    );
    history.push("/outpay");
    axios.defaults.headers.common["Authorization"] = `token ${token}`;
  } catch (error) {
    console.log("Wrong username or password");
  }
};
