import React from "react";
import DatePicker from "antd/lib/date-picker";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: left;
`;

const Label = styled.div`
  display: block;
`;

const FormDatePicker = ({ className, name, onDateChange, label, ...rest }) => {
  return (
    <Wrapper className={className}>
      <Label>{label}:</Label>
      <DatePicker
        {...rest}
        name={name}
        onChange={(value) => {
          onDateChange(name, value);
        }}
      />
    </Wrapper>
  );
};

export default FormDatePicker;
