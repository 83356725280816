import React from "react";
import Input from "antd/lib/input";
import styled from "styled-components";

const { TextArea } = Input;

const Wrapper = styled.div`
  text-align: left;
`;

const FormTextarea = ({ className, label, ...rest }) => {
  return (
    <Wrapper className={className}>
      <label>{label}</label>:
      <TextArea {...rest} />
    </Wrapper>
  );
};

export default FormTextarea;
