import { Button } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import * as yup from "yup";
import { EsPageContainer } from "../../components";
import env from "../../environment";
import useEsQuery from "../../hooks/useEsQuery";
import history from "../../myHistory";
import { FormInput } from "../../shared/components";
import { postEsRequest, putEsRequest } from "../../utils";

const Content = styled.div``;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  float: right;
`;

const OutpayFormComponent = (props) => {
  const { id } = useParams();

  const { data } = useEsQuery(`${env.esavingBE}outpay_type/${id}/`);

  const validationSchema = yup.object({
    name: yup.string().required(),
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    formik.setValues(data);
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let result;
      try {
        if (!id) {
          result = await postEsRequest(`${env.esavingBE}outpay_types/`, values);
        } else {
          result = await putEsRequest(
            `${env.esavingBE}outpay_type/${id}/`,
            values
          );
        }
        history.push("/outpayType");
      } catch (e) {}
    },
  });

  return (
    <EsPageContainer title="Nhập loại chi tiêu">
      <Content>
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            label="Tên"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <SubmitButton
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
            fullWidth
            type="primary"
          >
            Lưu
          </SubmitButton>
        </form>
      </Content>
    </EsPageContainer>
  );
};

export default OutpayFormComponent;
