import {
  faFileInvoiceDollar,
  faStickyNote,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "antd/lib/menu";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 20px;

  .ant-menu-item {
    display: flex;
    align-items: center;
  }
`;

const Content = styled.div`
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
`;

const PageLayoutComponent = ({ children }) => {
  const [menuKey, setMenuKey] = useState("output");

  const handleClick = (e) => {
    setMenuKey(e.key);
  };

  return (
    <Wrapper>
      <Menu onClick={handleClick} selectedKeys={[menuKey]} mode="horizontal">
        <Menu.Item>
          <Title>ESaving</Title>
        </Menu.Item>
        <Menu.Item
          key="output"
          icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
        >
          <Link to="/outpay">Chi tiêu</Link>
        </Menu.Item>
        <Menu.Item key="note" icon={<FontAwesomeIcon icon={faStickyNote} />}>
          <Link to="/note">Ghi chú</Link>
        </Menu.Item>
        <Menu.Item
          key="payType"
          icon={<FontAwesomeIcon icon={faFileInvoice} />}
        >
          <Link to="/outpayType">Loại chi tiêu</Link>
        </Menu.Item>
      </Menu>

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default PageLayoutComponent;
