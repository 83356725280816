import { sumBy } from "lodash-es";
import moment from "moment";
import { REMOTE_DATE_FORMAT } from "./Outpay";
import groupBy from "lodash/groupBy";

export function getTreeData(list, groupByFormat) {
  if (!groupByFormat) {
    return list;
  }
  const monthDataDic = groupBy(list, ({ pay_date }) =>
    moment(pay_date, REMOTE_DATE_FORMAT).format(groupByFormat)
  );
  const treeData = Object.keys(monthDataDic).map((key) => {
    const value = monthDataDic[key];
    return {
      key,
      name: key,
      pay_date: value[value.length - 1].pay_date,
      amount: sumBy(value, "amount"),
      children: value.map((item) => ({ ...item, key: item.id })),
    };
  });
  return treeData;
}
