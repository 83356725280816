import React from "react";
import styled from "styled-components";
import Spin from "antd/lib/spin";

const Wrapper = styled.div``;

const LoadingComponent = () => {
  return (
    <Wrapper>
      <Spin />
    </Wrapper>
  );
};

export default LoadingComponent;
