/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { getEsRequest } from "../utils";

const useCompareMemoize = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useEsQuery = (url, params) => {
  const [result, setResult] = useState({ loading: true });
  const [preUrl, setPreUrl] = useState(null);
  const [preParams, setPreParams] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setResult({ loading: true });
      try {
        const resData = await getEsRequest(url, params);
        setResult({
          data: resData.data || resData,
          rawData: resData,
          loading: false,
        });
      } catch (error) {
        setResult({ error, loading: false });
      }
    };
    fetchData();
  }, useCompareMemoize([url, params]));

  if (url !== preUrl || !isEqual(params, preParams)) {
    setPreUrl(url);
    setPreParams(params);
    setResult({ loading: true });
  }

  return result;
};

export default useEsQuery;
