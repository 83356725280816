import { useEffect } from "react";
import "./App.css";
import ESavingRouter from "./routers";
import "./styles/index.scss";
import store from "store";
import axios from "axios";

function App() {
  useEffect(() => {
    const token = store.get("user")?.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = `token ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    const expirePlugin = require("store/plugins/expire");
    store.addPlugin(expirePlugin);
  }, []);

  return (
    <div className="App container">
      <ESavingRouter />
    </div>
  );
}

export default App;
