import { Button } from "antd";
import { useFormik } from "formik";
import React from "react";
import styled from "styled-components";
import * as yup from "yup";
import { FormInput } from "../shared/components";
import { login } from "./auth-service";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 1px;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  float: right;
`;

const Header = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
`;

const AuthComponent = (props) => {
  const validationSchema = yup.object({
    email: yup.string("Enter your email"),
    password: yup.string("Enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      username: "admin",
      password: "minhchau@Mm",
    },
    validationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  return (
    <Wrapper>
      <Content>
        <Header>Đăng nhập</Header>
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            label="Người dùng"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          <FormInput
            label="Mật khẩu"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Password"
            type="password"
          />
          <SubmitButton
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
            fullWidth
            type="primary"
          >
            Đăng nhập
          </SubmitButton>
        </form>
      </Content>
    </Wrapper>
  );
};

export default AuthComponent;
