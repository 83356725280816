import React from "react";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: left;
`;

const CustomInputNumber = styled(InputNumber)`
  display: block;
  width: 100%;
`;

const FormInput = ({
  className,
  isNumber = false,
  name,
  onNumberChange,
  label,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <label>{label}</label>:
      {isNumber ? (
        <CustomInputNumber
          {...rest}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          onChange={(value) => {
            onNumberChange(name, value);
          }}
          // onChange={function (value) {
          //   const e = this.event;
          //   onNumberChange && onNumberChange(value ? value.toString() : "");
          // }}
        />
      ) : (
        <Input name={name} {...rest} />
      )}
    </Wrapper>
  );
};

export default FormInput;
