import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouteMatch, Route } from "react-router";
import PageLayout from "../components/PageLayout";
import store from "store";
import history from "../myHistory";
import { Outpay, OutpayForm } from "../Outpay";
import { OutpayType, OutpayTypeForm } from "../OutpayType";
import { Note, NoteForm } from "../Note";

const PrivateRouteComponent = ({ Component, logout, ...rest }) => {
  const { path } = useRouteMatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const token = store.get("user")?.token;
    if (token) {
      setIsAuthenticated(true);
    } else {
      history.replace("/auth/login");
      setIsAuthenticated(false);
    }
  }, []);

  return (
    isAuthenticated && (
      <PageLayout>
        <Route exact path={`${path}`}>
          <Outpay {...rest} />
        </Route>
        <Route exact path={`${path}outpay`}>
          <Outpay {...rest} />
        </Route>
        <Route exact path={`${path}outpay/form`}>
          <OutpayForm {...rest} />
        </Route>
        <Route exact path={`${path}outpay/form/:id`}>
          <OutpayForm {...rest} />
        </Route>
        <Route exact path={`${path}outpayType`}>
          <OutpayType {...rest} />
        </Route>
        <Route exact path={`${path}outpayType/form`}>
          <OutpayTypeForm {...rest} />
        </Route>
        <Route exact path={`${path}outpayType/form/:id`}>
          <OutpayTypeForm {...rest} />
        </Route>
        <Route exact path={`${path}note`}>
          <Note {...rest} />
        </Route>
        <Route exact path={`${path}note/form`}>
          <NoteForm {...rest} />
        </Route>
        <Route exact path={`${path}note/form/:id`}>
          <NoteForm {...rest} />
        </Route>
      </PageLayout>
    )
  );
};

PrivateRouteComponent.propTypes = {
  component: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired,
};

export default PrivateRouteComponent;
