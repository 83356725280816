import React, { useEffect, useMemo, useState } from "react";
import Table from "antd/lib/table";
import numeral from "numeral";
import { EsPageContainer } from "../components";
import styled from "styled-components";
import history from "../myHistory";
import { Link } from "react-router-dom";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteEsRequest } from "../utils";
import env from "../environment";
import moment from "moment";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import { getTreeData } from "./outpayService";

const { Option } = Select;
const { Search } = Input;

export const REMOTE_DATE_FORMAT = "yyyy-MM-DD";
export const DATE_FORMAT = "DD/MM/yyyy";

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FilterContent = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Filter = styled.div`
  margin-right: 20px;
`;

const toFormPage = () => {
  history.push("outpay/form");
};

export const GROUP_BY_KEY = {
  month: { value: "MM/yyyy", label: "Tháng" },
  day: { value: "DD/MM/yyyy", label: "Ngày" },
  year: { value: "yyyy", label: "Năm" },
  none: { value: "", label: "Không gộp" },
};

const OutpayComponent = ({ data, reload, setSearch }) => {
  const [groupByFormat, setGroupByFormat] = useState(GROUP_BY_KEY.month.value);

  const columns = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`outpay/form/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (value) => {
        return numeral(value).format("0,0");
      },
    },
    {
      title: "Ngày chi",
      dataIndex: "pay_date",
      key: "pay_date",
      render: (value) => moment(value, REMOTE_DATE_FORMAT).format(DATE_FORMAT),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Bạn có muốn xoá chi tiêu này không?"
            onConfirm={removeOutpay.bind(null, record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        );
      },
    },
  ];

  const removeOutpay = async (id) => {
    try {
      const result = await deleteEsRequest(`${env.esavingBE}outpay/${id}/`);
      reload();
    } catch (e) {}
  };

  const treeData = useMemo(() => {
    return getTreeData(data, groupByFormat);
  }, [data, groupByFormat]);

  return (
    <EsPageContainer title="Danh sách chi tiêu">
      <Header>
        <Button onClick={toFormPage}>Thêm chi tiêu</Button>
      </Header>
      <FilterContent>
        <Filter>
          <span>Gộp bởi:</span>
          <Select
            defaultValue={groupByFormat}
            style={{ width: 120 }}
            onChange={setGroupByFormat}
          >
            {Object.values(GROUP_BY_KEY).map(({ value, label }) => (
              <Option value={value}>{label}</Option>
            ))}
          </Select>
        </Filter>
        <Filter>
          <Search
            placeholder="Tìm theo tên"
            onSearch={setSearch}
            style={{ width: 200 }}
          />
        </Filter>
      </FilterContent>
      {data && <Table columns={columns} dataSource={treeData} checkStrictly />}
    </EsPageContainer>
  );
};

export default OutpayComponent;
