import queryString from "query-string";
import axios from "axios";
import store from "store";

export const getEsAuthHeader = () => {
  const token = store.get("user").token;
  return {
    auth: token,
    "Access-Control-Allow-Origin": "*",
  };
};

export const getEsRequest = (url, params) =>
  axios
    .get(url, {
      params,
      // headers: getAnalyticAuthHeader(),
      paramsSerializer: (originalParams) => {
        return queryString.stringify(originalParams, { arrayFormat: "comma" });
      },
    })
    .then((res) => {
      return res.data;
    });

export const postEsRequest = (url, data) => {
  return axios.post(url, data).then((res) => {
    return res.data;
  });
};

export const putEsRequest = (url, data) => {
  return axios.put(url, data).then((res) => {
    return res.data;
  });
};

export const deleteEsRequest = (url) => {
  return axios.delete(url).then((res) => {
    return res.data;
  });
};
