import { Router, Switch, Route } from "react-router";
import history from "../myHistory";
import AuthComponent from "../Auth";
import Outpay from "../Outpay";
import PrivateRoute from "./PrivateRoute";
import store from "store";

function ESavingRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact={true}
          path="/auth/login"
          render={() => <AuthComponent />}
        ></Route>
        <PrivateRoute path="/"></PrivateRoute>
      </Switch>
    </Router>
  );
}

export default ESavingRouter;
