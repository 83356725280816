import React from "react";
import Table from "antd/lib/table";
import { EsPageContainer } from "../components";
import styled from "styled-components";
import history from "../myHistory";
import { Link } from "react-router-dom";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteEsRequest } from "../utils";
import env from "../environment";

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const toFormPage = () => {
  history.push("note/form");
};

const NoteComponent = ({ data, reload }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`note/form/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nội dung",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Bạn có muốn xoá ghi chú này không?"
            onConfirm={removeOutpay.bind(null, record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        );
      },
    },
  ];

  const removeOutpay = async (id) => {
    try {
      const result = await deleteEsRequest(`${env.esavingBE}note/${id}/`);
      reload();
    } catch (e) {}
  };

  return (
    <EsPageContainer title="Danh sách ghi chú">
      <Header>
        <Button onClick={toFormPage}>Thêm ghi chú</Button>
      </Header>
      {data && <Table columns={columns} dataSource={data} />}
    </EsPageContainer>
  );
};

export default NoteComponent;
