import devEnvironment from "./env.dev";
import prodEnvironment from "./env.prod";

const getEnv = () => {
  if (process.env.REACT_APP_DEPLOYMENT_ENV === "dev") {
    return devEnvironment;
  }
  return prodEnvironment;
};

const env = getEnv();

export default env;
