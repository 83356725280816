import React from "react";
import Table from "antd/lib/table";
import { EsPageContainer } from "../components";
import styled from "styled-components";
import history from "../myHistory";
import { Link } from "react-router-dom";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteEsRequest } from "../utils";
import env from "../environment";

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const toFormPage = () => {
  history.push("outpayType/form");
};

const OutpayTypeComponent = ({ data, reload }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`outpayType/form/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Bạn có muốn xoá chi tiêu này không?"
            onConfirm={removeOutpay.bind(null, record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        );
      },
    },
  ];

  const removeOutpay = async (id) => {
    try {
      const result = await deleteEsRequest(
        `${env.esavingBE}outpay_type/${id}/`
      );
      reload();
    } catch (e) {}
  };

  return (
    <EsPageContainer title="Danh sách loại chi tiêu">
      <Header>
        <Button onClick={toFormPage}>Thêm chi tiêu</Button>
      </Header>
      {data && <Table columns={columns} dataSource={data} />}
    </EsPageContainer>
  );
};

export default OutpayTypeComponent;
