import React, { useState } from "react";
import OutpayComponent from "./Note";
import useEsQuery from "../hooks/useEsQuery";
import env from "../environment";
import Loading from "../components/Loading";

const NoteContainerComponent = () => {
  const [version, updateVersion] = useState(0);
  const { data, loading } = useEsQuery(`${env.esavingBE}notes/`, {
    version,
  });

  const increaseVersion = () => {
    updateVersion(version + 1);
  };

  if (loading) return <Loading />;

  return <OutpayComponent data={data} reload={increaseVersion} />;
};

export default NoteContainerComponent;
