import { Button } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import * as yup from "yup";
import { EsPageContainer } from "../../components";
import env from "../../environment";
import useEsQuery from "../../hooks/useEsQuery";
import history from "../../myHistory";
import { FormDatePicker, FormInput, FormSelect } from "../../shared/components";
import { postEsRequest, putEsRequest } from "../../utils";
import Select from "antd/lib/select";
import { DATE_FORMAT, REMOTE_DATE_FORMAT } from "../Outpay";

const { Option } = Select;

const Content = styled.div``;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  float: right;
`;

const OutpayFormComponent = (props) => {
  const { id } = useParams();

  const { data } = useEsQuery(`${env.esavingBE}outpay/${id}/`);
  const { data: outpayTypes } = useEsQuery(`${env.esavingBE}outpay_types/`);

  const validationSchema = yup.object({
    name: yup.string().required(),
    // amout: yup.number().required(),
    // outpayType: yup.number().required(),
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const { pay_date, ...rest } = data;
    formik.setValues({
      ...rest,
      pay_date: moment(pay_date),
    });
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
      number_of_items: 0,
      amount: 0,
      pay_date: moment(new Date()),
      out_pay_type: null,
      description: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let result;
      try {
        if (!id) {
          result = await postEsRequest(`${env.esavingBE}outpays/`, {
            ...values,
            pay_date: moment(values.pay_date).format(REMOTE_DATE_FORMAT),
          });
        } else {
          result = await putEsRequest(`${env.esavingBE}outpay/${id}/`, {
            ...values,
            pay_date: moment(values.pay_date).format(REMOTE_DATE_FORMAT),
          });
        }
        history.push("/outpay");
      } catch (e) {}
    },
  });

  return (
    <EsPageContainer title="Nhập chi tiêu">
      <Content>
        <form onSubmit={formik.handleSubmit}>
          <FormInput
            label="Tên"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <FormInput
            label="Số lượng"
            name="number_of_items"
            isNumber
            value={formik.values.number_of_items}
            onNumberChange={formik.setFieldValue}
            placeholder="Số lượng hàng"
          />
          <FormInput
            label="Số tiền"
            name="amount"
            value={formik.values.amount}
            isNumber
            onNumberChange={formik.setFieldValue}
            placeholder="Số tiền"
          />
          <FormDatePicker
            label="Ngày chi tiêu"
            name="pay_date"
            value={formik.values.pay_date}
            onDateChange={formik.setFieldValue}
            format={DATE_FORMAT}
          />
          <FormSelect
            label="Loại chi tiêu"
            name="out_pay_type"
            value={formik.values.out_pay_type}
            onChange={formik.setFieldValue}
          >
            {outpayTypes &&
              outpayTypes.map((o) => <Option value={o.id}>{o.name}</Option>)}
          </FormSelect>
          <SubmitButton
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
            fullWidth
            type="primary"
          >
            Lưu
          </SubmitButton>
        </form>
      </Content>
    </EsPageContainer>
  );
};

export default OutpayFormComponent;
